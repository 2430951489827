#root {
  height: 100%;
  width: auto;
}

.appContainer {
  position: fixed;
  width: 100%;
  height: 100%;  
  background: content-box linear-gradient(black, white)
}

.canvasContainer {
  height: 80%;
  width: 90%;
  
}
.canvasContainer::after{
  display:block;
}

#myImage {
  width:120%; 
  height: 140%; 
  position: fixed; 
  top: -24%;
  left: -10%;
}

.mainCanvas {
  width: 100%;
  height: 100%; 
}

.mainTitle {  
  width: 100%;
  height: 10%; 
  position: fixed; 
  display: flex;
  justify-content: center;
  align-items: center;
}

#samsonart {
  width: 40%;
  height: 120%;
  position: relative;
  z-index: 999;
  margin: auto;  
}

#loading {
  width: 100%;
  height: fit-content;
  position: fixed;
  top: 50%; 
  bottom: 50%; 
  text-align: center;
}

.hidden {
  display: none !important;
}
